import React from "react";
import { useModal } from "../context/ModelContext";

const Modal = () => {
  const { isModalOpen, closeModal, modalContent } = useModal();

  if (!isModalOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>✖</button>
        {modalContent}
      </div>
    </div>
  );
};

export default Modal;
