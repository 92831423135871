import React, { useState } from "react";
import { useForm } from "react-hook-form";

const SimpleContactForm = ({title,btnTitle}) => {
  const [message, setMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "353dd854-1ca9-4730-8291-1ead344c700d",
          name: data.name,
          email: data.email,
          contact: data.contact,
          message: data.message,
          subject: "Syllabus Request Form Submission",
          from_name: "ITAccurate",
        }),
      });

      const result = await response.json();

      if (result.success) {
        alert("Message sent successfully!");
        reset();
        setMessage(""); // Clear message after submission
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 shadow-lg bg-black bg-opacity-30 backdrop-blur-md rounded-md">
      <h3 className="text-xl font-bold mb-4">{title}</h3>
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            type="text"
            placeholder="Your Name"
            {...register("name", {
              required: "Name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters long",
              },
            })}
            className="w-full border text-black border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {errors.name && (
            <p className="text-red-500 text-sm">{errors.name.message}</p>
          )}
        </div>
        <div>
          <input
            type="tel"
            placeholder="Contact Number"
            {...register("contact", {
              required: "Contact number is required",
              pattern: {
                value: /^[0-9]{10}$/,
                message: "Enter a valid 10-digit contact number",
              },
            })}
            className="w-full border text-black border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {errors.contact && (
            <p className="text-red-500 text-sm">{errors.contact.message}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          {btnTitle}
        </button>
      </form>
    </div>
  );
};

export default SimpleContactForm;
