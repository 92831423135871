import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Scroll from './Components/SmoothScroll';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Marquee from './Components/Marquee'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer';
import ContactForm from './Components/ContactForm';
import DevOps from './pages/DevOps';
import BlogList from './Blog/BlogList';
import BlogDetails from './Blog/BlogDetails';
import Salesforce from './pages/Salesforce';
import SalesforceAdmin from './pages/Salesforce/SalesforceAdmin'
import SalesforceDevlopment from './pages/Salesforce/SalesforceDevlopment';
import SalesforceMarketing from './pages/Salesforce/SalesforceMarketing';
import SalesforceLWC from './pages/Salesforce/SalesforceLWC';
import BA from './pages/BusinessAnalytics'
import FullStack from './pages/FullStackDev'
import DA from './pages/DataAnalytics'
import DS from './pages/DataScience'
import ML from './pages/MachineLearning'
import ShareMarket from './pages/ShareMarket'
import AWS from './pages/AWS'
import AIML from './pages/AIML'
import Tally from './pages/Tally'
import Python from './pages/Python'
import SAPMM from './pages/Sap/SAPMM'
import SAPHCM from './pages/Sap/SapHCM'
import SAPSUCCESSFACTORS from './pages/HR/SAPSUCCESSFACTORS'
import SAPSCM from './pages/Sap/SAPSCM'
import SAPABAP from './pages/Sap/SAPABAP'
import SAP from './pages/SAP'
import SAPARIBA from './pages/Sap/SAPARIBA'
import SAPFICO from './pages/Sap/SAPFICO'
import SAPFIORI from './pages/Sap/SAPFIORI'
import SAPPP from './pages/Sap/SAPPP'
import SAPPM from './pages/Sap/SAPPM'
import SAPSD from './pages/Sap/SAPSD'
import SAPBASIS from './pages/Sap/SAPBASIS'
import SAPQM from './pages/Sap/SAPQM'
import SAPWMEWM from './pages/Sap/SAPWMEWM'
import SAPLESL from './pages/Sap/SAPLESL'
import WhatsAppIcon from './Components/WhatsApp';
import SAPBUSINESSONE from './pages/Sap/SAPBusinessOne'
import PRIVACY from './pages/Terms/Privacy'
import TERMS from './pages/Terms/Terms'
import REFUND from './pages/Terms/Refund'
import CallIcon from './Components/CallIcon';
import CarrerPage from './pages/Carrer';
import Placed from './pages/PlacedStudents';
import BatchesPage from './Components/Batches';
import { ModalProvider } from './context/ModelContext';
import SalaryHike from './Components/SalaryHike'
import ServiceNow from './pages/ServiceNow'
import SAPHR from './pages/HR/SAPHR'
import SAPBTP from './pages/Sap/SAPBTP.js'
import BAHR from './pages/HR/BAHR.js'
import DAHR from './pages/HR/DAHR'
import Jobs from './pages/Jobs.js';
import DataEngineering from './pages/DataEngineering.js';
import Modal from './Components/Model.js';

function App() {
  return (
    <ModalProvider>
      <Router>
        <WhatsAppIcon />
        <div className="App">
          <Modal />
          <Navbar />
          <ContactForm heading="Contact Form!" positionStyle={{ position: "fixed" }} showCloseButton={true}/>
          <CallIcon />
          <WhatsAppIcon />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<CarrerPage />} />
            <Route path='/blogDetails/:id' element={<BlogDetails />} />
            <Route path='/b' element={<BlogList />} />
            <Route path="/salesforce-training" element={<Salesforce />} />
            <Route path="/salesforce-admin" element={<SalesforceAdmin />} />
            <Route path="/salesforce-development-2" element={<SalesforceDevlopment />} />
            <Route path="/salesforce-marketing-cloud" element={<SalesforceMarketing />} />
            <Route path="/salesforce-lwc-integration" element={<SalesforceLWC />} />
            <Route path='/best-devops-training' element={<DevOps />} />
            <Route path='/best-ai-ml-training' element={<AIML />} />
            <Route path='/best-aws-training' element={<AWS />} />
            <Route path='/best-data-science-training' element={<DS />} />
            <Route path='/best-data-analytics-training' element={<DA />} />
            <Route path='/best-machine-learning-training' element={<ML />} />
            <Route path='/best-business-analytics-training' element={<BA />} />
            <Route path='/share-market' element={<ShareMarket />} />
            <Route path='/full-stack-developer' element={<FullStack />} />
            <Route path='/tally' element={<Tally />} />
            <Route path='/python' element={<Python />} />
            <Route path='/sap-mm' element={<SAPMM />} />
            <Route path='/sap-hcm' element={<SAPHCM />} />
            <Route path='/sap-successfactors' element={<SAPSUCCESSFACTORS />} />
            <Route path='/sap-scm' element={<SAPSCM />} />
            <Route path='/sap-abap' element={<SAPABAP />} />
            <Route path='/sap-fico' element={<SAPFICO />} />
            <Route path='/best-sap-training' element={<SAP />} />
            <Route path='/sap-ariba' element={<SAPARIBA />} />
            <Route path='/sap-pp' element={<SAPPP />} />
            <Route path='/sap-sd' element={<SAPSD />} />
            <Route path='/sap-fiori' element={<SAPFIORI />} />
            <Route path='/sap-basis-training-in-nagpur' element={<SAPBASIS />} />
            <Route path='/sap-qm' element={<SAPQM />} />
            <Route path='/sap-wm-ewm' element={<SAPWMEWM />} />
            <Route path='/sap-pm' element={<SAPPM />} />
            <Route path='/sap-le-sl' element={<SAPLESL />} />
            <Route path='/sap-business-one' element={<SAPBUSINESSONE />} />
            <Route path='/sap-btp-training' element={<SAPBTP />} />
            <Route path='/privacy' element={<PRIVACY />} />
            <Route path='/terms' element={<TERMS />} />
            <Route path='/refund' element={<REFUND />} />
            <Route path='/placed' element={<Placed />} />
            <Route path="/batch" element={<BatchesPage />} />
            <Route path="/salaryhike" element={<SalaryHike />} />
            <Route path="/servicenow" element={<ServiceNow />} />
            <Route path="/sap-hr" element={<SAPHR />} />
            <Route path="/da-hr" element={<DAHR />} />
            <Route path="/ba-hr" element={<BAHR />} />
            <Route path="/jobs-openings" element={<Jobs />} />
            <Route path="/data-engineering" element={<DataEngineering />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ModalProvider>
  );
}

export default App;