import React, { useState } from "react";
import GoogleMap from "../Components/GoogleMap";
import config from "../config";
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    course: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required.";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 10 digits.";
    }
    if (!formData.course) {
      errors.course = "Please select a course.";
    }
    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error when input is modified
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    setIsSubmitting(true);
  
    // Combine Web3Forms access key with form data
    //shivanihiware mail
    const formSubmissionData = {
      access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
      ...formData,
    };
  
    try {
      // Step 1: Submit data to Web3Forms
      const web3FormsResponse = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formSubmissionData),
      });
  
      const web3FormsResult = await web3FormsResponse.json();
  
      if (web3FormsResult.success) {
        // Step 2: Save data to the database
        // for localhost https://localhost/save_form_data.php
        const dbResponse = await fetch(`${config.apiUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        const dbResult = await dbResponse.json();
  
        if (dbResult.success) {
          setSuccessMessage("Form submitted successfully!");
        } else {
          setSuccessMessage("Form submitted successfully, but database save failed.");
          console.error("Database error:", dbResult.message || "Unknown error");
        }
  
        // Reset form
        setFormData({
          name: "",
          phone: "",
          course: "",
        });
      } else {
        setErrorMessage("Failed to submit the form to Web3Forms. Please try again.");
        console.error("Web3Forms error:", web3FormsResult.message || "Unknown error");
      }
    } catch (error) {
      // Handle network or unexpected errors
      setErrorMessage("An error occurred. Please try again later.");
      console.error("Submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  return (
    <div>
      {/* Inline Styles */}
      <style>{`
        .contact-form-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 20px;
          padding: 20px;
          border: 0.5px solid gray;
          border-radius: 10px;
          max-width: 900px;
          margin: 2rem auto;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          background-color: transparent;
          margin-top: 5rem;
        }

        .contact-form-content {
          flex: 1;
          min-width: 300px;
          padding: 10px;
        }

        .contact-form-map {
          flex: 1;
          min-width: 300px;
          padding: 10px;
          border-radius: 10px;
          overflow: hidden;
        }

        .contact-form-content h2 {
          text-align: center;
          margin-bottom: 20px;
          color: white;
        }

        .contact-form-content form div {
          margin-bottom: 15px;
        }

        .contact-form-content label {
          font-weight: bold;
          display: block;
        }

        .contact-form-content input,
        .contact-form-content select {
          width: 100%;
          padding: 10px;
          margin: 5px 0;
          border-radius: 5px;
          border: 1px solid #ccc;
            color:black;
        }

        .contact-form-content input.error,
        .contact-form-content select.error {
          border: 1px solid red;
        }

        .contact-form-content button {
          padding: 10px 20px;
          background-color: #7B1FA2;
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }

        .contact-form-content p {
          text-align: center;
          margin-top: 10px;
        }

        .contact-form-content p.success {
          color: green;
        }

        .contact-form-content p.error {
          color: red;
        }

        .error-message {
          color: red;
          font-size: 0.9rem;
          margin-top: 5px;
        }

        @media (max-width: 768px) {
          .contact-form-container {
            flex-direction: column;
            align-items: center;
          }

          .contact-form-content,
          .contact-form-map {
            width: 100%;
          }
        }
      `}</style>

      <div className="contact-form-container">
        {/* Contact Form */}
        <div className="contact-form-content">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? "error" : ""}
                required
              />
              {errors.name && <div className="error-message">{errors.name}</div>}
            </div>
            <div>
              <label htmlFor="phone">Phone No.:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Your Phone no."
                value={formData.phone}
                onChange={handleChange}
                className={errors.phone ? "error" : ""}
                required
              />
              {errors.phone && <div className="error-message">{errors.phone}</div>}
            </div>
            <div>
              <label htmlFor="course">Select Your Course:</label>
              <select
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                className={errors.course ? "error" : ""}
                required
              >
                <option value="">-- Select a Course --</option>
                <option value="SAP">SAP</option>
                <option value="Salesforce">Salesforce</option>
                <option value="AWS">AWS</option>
                <option value="DevOps">DevOps</option>
                <option value="Python">Python</option>
                <option value="AI & ML">AI & ML</option>
                <option value="Data Analytics">Data Analytics</option>
                <option value="Business Analytics">Business Analytics</option>
                <option value="ServiceNow">ServiceNow</option>
                <option value="HR Training">HR Training</option>
                <option value="Share Market">Share Market</option>
              </select>
              {errors.course && <div className="error-message">{errors.course}</div>}
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
          {successMessage && <p className="success">{successMessage}</p>}
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>

        {/* Google Map */}
        <div className="contact-form-map">
          <GoogleMap />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;